@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Work Sans", sans-serif;
  font-size: 1.5em;
  height: 100vh;
  color: #000000de;
  /* background-color: #f8f9fa; */
  background-color: #f6f7fb;
  /* overflow: hidden; */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Work Sans";
  font-weight: 500;
  /* color: #2e3856; */
}

.medium {
  font-family: "Work Sans";
  font-weight: 600;

}

.clickable_div {
  width: 25%;
  height: 23vh;
  border-radius: 10px;
  margin: 10px;
  border: solid 2px #d9dadc;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
/* 
.clickable_div:hover {
  border: solid 2px #aeb3bd;
} */

.button_background {
  background-color: #f6f7fb;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  transition: background-color 0.3s;
}


/* Add the new hover styles for .clickable_div that target the .button_background div */
.clickable_div:hover .button_background {
  background-color: #e6e7eb; /* The new background color on hover */
}

.logout {
  right: 7%; 
  top: 1.8%;
}

.past_feedback {
  left: 4%;
  top: 11%;
}

.skip {
  right: 7%;
  top: 11%;
}

.back {
  right: 12%;
  top: 11%;
}

.login_button {
  background-color: #f6f7fb;
  border: solid 1.5px #d9dadc;
  border-radius: 0.5em;
  cursor: pointer;
  margin-top: 20px;
}

.login_button:hover {
  background-color: #e6e7eb; /* The new background color on hover */
}

.utility_button {
  background-color: #f6f7fb;
  border: solid 1.5px #d9dadc;
  border-radius: 0.5em;
  position: absolute; 
  cursor: pointer;
}

.utility_button:hover {
  background-color: #e6e7eb; /* The new background color on hover */
  /* border: solid 1.5px #aeb3bd; */
}

.utility_button_text {
  color: #2e3856;
  margin: 0px;
  font-size: 1.4em;
  padding: 8px;
}

.attempt_button {
  background-color: #f6f7fb;
  border-top: solid 1.5px #d9dadc;
  border-bottom: solid 1.5px #d9dadc;
  padding: 10px;
  padding-bottom: 12px;
  margin-bottom: 10px;
  text-align: left;
}

.attempt_button:hover {
  background-color: #e6e7eb;
}

.attempt_text {
  font-size: 1.2em;
  color: gray;
  font-family: Work Sans;
  position: relative;
  left: 8%;
}



.blue {
  color:#085e99;
}

div.login_box {
  background-color: white !important;
  padding: 100px;
  filter: drop-shadow(0 0 0.10rem rgb(197, 195, 195));
  border-radius: 50px;
}

.login__textBox {
  font-family: Work Sans;
  font-size: 0.6em;
}

nav h1 {
  margin: 0;
  font-weight: 100;
  font-size: 2em;
}

#root {
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.container {
  /* display: flex; */
  /* flex-direction: row; */
  width: 70%;
  /* height: 120%; */
  /* min-height: 120%; */
  /* flex-grow: 1; */
  margin-left: 15%;
  margin-top: 2%;
  background-color: white;
  border-radius: 0.75em;
  box-shadow: 4px 4px 20px 0px rgb(0 0 0 / 0.05);
  margin-bottom: 50px;
}

.characters {
  font-size: 3em;
}

.audio-recorder {
  margin: 0 auto !important;
  transform: scale(2);
  margin: 2em;
}

.audio-recorder:hover {
  opacity: 0.3;
}

.recording-box {
  margin: 3em;
}

.side-bar {
  width: 30%;
  max-width: 400px;
  background-color: white;
  height: 100%;
  padding: 2em;
  box-shadow: -4px 0px 30px 0px rgb(0 0 0 / 0.15);
}

.guide {
  /* width: 70%; */
  /* flex-grow: 1; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
  padding-left: 2em;
  padding-right: 2em;
}

.panel {
  width: 100%;
  max-width: 500px;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #e6e6e6;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.05),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
  margin-bottom: 2em;
}

.record-box {
  padding: 2em;
}

.english-breakdown {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 2em;
}

.english-subunit {
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0px;
  background-color: #d9ead3;
}

.english-subunit .en {
  font-weight: 800;
  font-size: 2em;
  line-height: 0.8em;
}

.english-subunit .phoneme {
  font-weight: 400;
  font-size: 10px;
}

.english-subunit.error-true {
  background-color: #f4cccc;
}

.english-subunit.word-end-true {
  margin-right: 20px;
}

.statistics2 {
  display: flex;
  flex-direction: row;
}

.panel.feedback {
  max-width: 100%;
  padding: 1em;
}

.comments {
  display: grid;
  justify-content: center;
  gap: 4px;
  row-gap: 20px;
  grid-template-columns: repeat(3, 260px);
}

.comment-panel {
  width: 250px;
  font-size: 0.5em;
  border-radius: 5px;
  border: 1px solid #eee;
  margin: 0 1em;
  text-align: left;
  flex-grow: 0;
  box-shadow: 4px 4px 20px 0px rgb(0 0 0 / 0.05);
}

.comment-panel h2 {
  width: 100%;
  background-color: #eee;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin: 0;
  padding: 1em;
}

.comment-panel ul {
  margin-left: 0em;
  padding-left: 30px;
}

.comment-panel p {
  margin: 0;
}

.progress {
  display: inline-block;
  margin: 50px;
  width: 150px;
  height: 150px;
}

.progress2 {
  display: flex;
  flex-direction: row;
  /* margin: 30px;
  margin-top: 0px;
  width: 80px;
  height: 80px; */
  /* width: 100px; */
}

.comment-panel .feedback-container {
  padding: 1em;
}

input {
  width: 100%;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 3px #ddd;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
}
