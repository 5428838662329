.Login-header {
  /* background-color: #030b1d; #0d1116 */
  /* background: rgb(60, 59, 148); */
  /* background: linear-gradient(
    135deg,
    rgba(60, 59, 148, 1) 0%,
    rgba(3, 11, 29, 1) 36%,
    rgba(3, 11, 29, 1) 100%
  ); */
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: absolute;
}
