.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: Radikal;
  /* src: url("./fonts/ProductSans-Regular.otf") format("opentype"); */
  src: url("./fonts/Radikal/Nootype\ -\ Radikal.otf") format("opentype");
}

@font-face {
  font-family: Hurme;
  src: url("./fonts/Hurme\ Geometric\ Sans\ No.2/HurmeGeometricSans2.otf")
    format("opentype");
}

@font-face {
  font-family: Hurme-Bold;
  src: url("./fonts/Hurme\ Geometric\ Sans\ No.2/HurmeGeometricSans2 SemiBold.otf")
    format("opentype");
}

@font-face {
  font-family: Radikal-Bold;
  src: url("./fonts/Radikal/Nootype\ -\ Radikal\ Medium.otf")
    format("opentype");
}

/* Montserrat */
@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: Monserrat Medium;
  src: url("./fonts/Montserrat/Montserrat-Medium.ttf") format("truetype");
}



/* Product Sans Regular */
@font-face {
  font-family: "Product Sans";
  src: url("./fonts/ProductSans-Regular.otf") format("opentype");
}

/* Product Sans Thin Regular */
@font-face {
  font-family: "Product Sans Thin";
  src: url("./fonts/ProductSans-Thin.otf") format("opentype"),
       local("Product Sans Thin Regular");
}

/* Product Sans Light Regular */
@font-face {
  font-family: "Product Sans Light";
  src: url("./fonts/ProductSans-Light.otf") format("opentype"),
       local("Product Sans Light Regular");
}

/* Product Sans Medium Regular */
@font-face {
  font-family: "Product Sans Medium";
  src: url("./fonts/ProductSans-Medium.otf") format("opentype"),
       local("Product Sans Medium Regular");
}

/* Product Sans Black Regular */
@font-face {
  font-family: "Product Sans Black";
  src: url("./fonts/ProductSans-Black.otf") format("opentype"),
       local("Product Sans Black Regular");
}

/* Product Sans Thin Italic */
@font-face {
  font-family: "Product Sans Thin Italic";
  src: url("./fonts/ProductSans-ThinItalic.otf") format("opentype"),
       local("Product Sans Thin Italic");
}

/* Product Sans Light Italic */
@font-face {
  font-family: "Product Sans Light Italic";
  src: url("./fonts/ProductSans-LightItalic.otf") format("opentype"),
       local("Product Sans Light Italic");
}

/* Product Sans Medium Italic */
@font-face {
  font-family: "Product Sans Medium Italic";
  src: url("./fonts/ProductSans-MediumItalic.otf") format("opentype"),
       local("Product Sans Medium Italic");
}

/* Product Sans Bold */
@font-face {
  font-family: "Product Sans Bold";
  src: url("./fonts/ProductSans-Bold.otf") format("opentype"),
       local("Product Sans Bold");
}

/* Product Sans Bold Italic */
@font-face {
  font-family: "Product Sans Bold Italic";
  src: url("./fonts/ProductSans-BoldItalic.otf") format("opentype"),
       local("Product Sans Bold Italic");
}

/* Product Sans Black Italic */
@font-face {
  font-family: "Product Sans Black Italic";
  src: url("./fonts/ProductSans-BlackItalic.otf") format("opentype"),
       local("Product Sans Black Italic");
}
